import React from "react";
import { PageLayout, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class FeedbackPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"Feedback-page"} hero={null}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"left"} width={"8"}>
              <ContactForm
                destinationEmail={"service@papiswatson.com"}
                constructMessage={({
                  name,
                  email,
                  phone,
                  nameServer,
                  date,
                  weekDay,
                  service,
                  taste,
                  exp,
                  dclean,
                  bclean,
                  message,
                }) =>
                  `Someone has reached out to you via your website Feedback form: Name: ${name}, Email: ${email}, Phone: ${phone}, Name Server: ${nameServer}, Date: ${date}, Week Day: ${weekDay}, Speed of Service: ${service}, Taste of Food: ${taste}, Customer Service / Experience: ${exp}, Cleanliness of Dinning Area: ${dclean}, Bathroom Cleanliness: ${bclean}, How can we improve our food or Menu: ${message}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "nameServer",
                    displayLabel: "Name of Server",
                    placeholder: "Name of Server",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "date",
                    displayLabel: "Date",
                    placeholder: "Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "weekDay",
                    displayLabel: "Week Day",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Monday", value: "Monday" },
                      { label: "Tuesday", value: "Tuesday" },
                      { label: "Wednesday", value: "Wednesday" },
                      { label: "Thursday", value: "Thursday" },
                      { label: "Friday", value: "Friday" },
                      { label: "Saturday", value: "Saturday" },
                      { label: "Sunday", value: "Sunday" },
                    ],
                  },
                  {
                    label: "service",
                    displayLabel: "Speed of Service",
                    type: "select",
                    required: true,
                    options: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                    ],
                  },
                  {
                    label: "taste",
                    displayLabel: "Taste of Food",
                    type: "select",
                    required: true,
                    options: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                    ],
                  },
                  {
                    label: "exp",
                    displayLabel: "Customer Service / Experience",
                    type: "select",
                    required: true,
                    options: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                    ],
                  },
                  {
                    label: "dclean",
                    displayLabel: "Cleanliness of Dinning Area",
                    type: "select",
                    required: true,
                    options: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                    ],
                  },
                  {
                    label: "bclean",
                    displayLabel: "Bathroom Cleanliness",
                    type: "select",
                    required: true,
                    options: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                    ],
                  },
                  {
                    label: "message",
                    displayLabel: "How can we improve our food or Menu?",
                    placeholder: "How can we improve our food or Menu?",
                    type: "textarea",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
